
.checkout-summary-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 8px;
    gap: 8px;

    .checkout-label,
    .checkout-amount {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;

        @media screen and (min-width: 1024px) {
            font-size: 16px
            line-height: 24px
        }
    }

    .checkout-label-bold,
    .checkout-amount-bold {
        font-family: "SF Pro Display";
        font-size: 16px
        font-weight: 500
        line-height: 24px

        @media screen and (min-width: 1024px) {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 1.875rem;
            letter-spacing: .03rem;

        }
    }
}
.checkout-summary-box {
    width 100%
    border: 1px solid $color-gray85;
    border-radius: 8px;
    padding: 18px;
}

.checkout-label {
    width: 50%
    max-width: 50%

    @media (min-width: 768px) {
        width: fit-content;
        max-width: fit-content;

    }
}

.checkout-amount,
.checkout-amount-bold {
    text-align: right
}

.alert-info {
    background-color: $color-bnn-light
    border-radius: 8px
    padding: 8px 16px
    font-size: 12px

    display: flex
    align-items: center
    gap: 8px

    .v-icon {
        color: $color-bnn
    }
}

.marketing-banner-html {
    max-width: 100%
    overflow: hidden
}
